import * as React from "react"
import { graphql } from "gatsby"
import { Avatar, Button, Col, List, Rate, Row, Typography } from "antd"
import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import moment from "moment"
import style from "../styles/pages/guest-reviews.module.scss"

const NUM_CHUNKS = 4

const description = `At Lakeside Lodge Clemson, we study guest feedback to help us to improve our services. Here are some of our favorite guest testimonials from recent guests.`

const chunkThisArray = (arr, numChunks) => {
  let chunks = []
  const chunkSize = Math.ceil(arr.length / numChunks)
  for (let i = 0; i < numChunks; i++) {
    const chunkStart = i * chunkSize
    const newChunk = arr.slice(chunkStart, chunkStart + chunkSize)
    chunks.push(newChunk)
  }
  return chunks
}

const Header = ({ author, avatar, stars, time, url }) => (
  <div className={style.headerWrapper}>
    <a href={url}>
      <Avatar size={44} src={avatar} />
    </a>
    <div className={style.titleAndRatingAndTime}>
      <a href={url}>
        <Typography.Text className={style.title}>{author}</Typography.Text>
      </a>
      <div className={style.ratingAndTime}>
        <Rate className={style.rating} defaultValue={stars} disabled />
        <span className={style.time}>
          {moment.unix(time).format(`MMM Do, YYYY`)}
        </span>
      </div>
    </div>
  </div>
)

export default ({ data: { hero, reviews } }) => {
  const chunks = chunkThisArray(reviews.nodes, NUM_CHUNKS)

  const [chunkIndex, setChunkIndex] = React.useState(0)

  const meta = {
    seo: {
      title: "Guest Reviews | Lakeside Lodge Clemson",
      img: hero.localFile.childImageSharp.fluid.src,
      imgAlt: hero.description,
      desc: description,
    },
    uri: "/guest-reviews/",
  }

  const showMoreReviews = () => {
    setChunkIndex(chunkIndex + 1)
  }

  const visibleReviews = chunks.reduce(
    (acc, cur, index) => (chunkIndex >= index ? [...acc, ...cur] : acc),
    []
  )

  return (
    <Shell>
      <Meta {...meta} />
      <Hero contentfulAsset={hero} height={`40vh`} />
      <BookingBar showCTAButtons={false} />
      <Row
        align="middle"
        className={style.introRow}
        justify="center"
        type="flex"
      >
        <Col xs={23} sm={22} md={18} lg={16} xl={14}>
          <Typography.Title>Guest Reviews</Typography.Title>
          <Typography.Paragraph>
            We value your feedback. Here are some of our recent guest
            testimonials:
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row
        align="middle"
        className={style.reviewsRow}
        justify="center"
        type="flex"
      >
        <Col xs={23} sm={22} md={18} lg={16} xl={14}>
          <List
            dataSource={visibleReviews}
            itemLayout="vertical"
            loadMore={
              visibleReviews.length < reviews.nodes.length ? (
                <div className={style.showMore}>
                  <Button onClick={showMoreReviews} size="large" type="primary">
                    Show More
                  </Button>
                </div>
              ) : null
            }
            renderItem={(review, index) => (
              <List.Item key={index} className={style.listItem}>
                <Header {...review} />
                <div className={style.reviewText}>{review.content}</div>
                <div className={style.reviewSource}>
                  Posted on <a href={review.url}>Google</a>
                </div>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Shell>
  )
}

export const query = graphql`
  query {
    hero: contentfulAsset(contentful_id: { eq: "6qUkh28dLf3O8Kd0jWez59" }) {
      description
      localFile {
        childImageSharp {
          fluid(maxWidth: 1500) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
    reviews: allGoogleReview {
      nodes {
        content: text
        stars: rating
        author: reviewer_name
        avatar: reviewer_picture_url
        url
        time: published_at
      }
    }
  }
`
